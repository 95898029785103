import { clientFetchWithKey, getUrl } from './config'
import { OPTION, REASON } from '../constants'

export const vote = async ({ questionId, optionId, reasonId, removeVote = false }) => {
  return clientFetchWithKey(getUrl('/votes'), {
    method: removeVote ? 'DELETE' : 'POST',
    body: JSON.stringify({
      questionId,
      reasonId,
      optionId,
      type: optionId ? OPTION : REASON
    })
  })
}
