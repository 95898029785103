import { Navbar, Offcanvas, Container, Nav } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const ColoredLink = styled(Nav.Link)`
  color: grey;

  :hover {
    color: black;
  }
`

const D = styled(Navbar.Brand)`
  font-family: Lora;
  font-size: 1.75rem;
`

export const NavBar = ({ currentUser = {}, logout }) => {
  const { push } = useHistory()
  return (
    <Navbar bg="white" expand={false}>
      <Container fluid className="justify-content-between ps-0">
      <D>D</D>
        <Navbar.Toggle aria-controls="offcanvasNavbar" className="text-right" />
        <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">D-cide</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <div className="d-flex">
                <p>Signed in as: {currentUser.displayName}</p>
              </div>
              <ColoredLink onClick={() => push('/')}>Home</ColoredLink>
              <ColoredLink onClick={() => push('/how-it-works')}>How It Works</ColoredLink>
              <ColoredLink onClick={() => push('/create')}>Create a Question</ColoredLink>
              <ColoredLink onClick={logout}>Logout</ColoredLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
