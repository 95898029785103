import { useState } from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import ReactTooltip from 'react-tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getOr } from 'lodash/fp'
import { useParams } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useFetchQuestion, vote, createReasons } from '../api'
import { ReasonInput, AvatarImg } from './common'
import { webBaseUrl } from '../utilities'
import { NavBar } from './NavBar'
import { useOptimisticMutate } from '../api/config'
import {
  swapOptionVote,
  voteForReason,
  addNewReason,
  preventNoValueSubmit,
  getVoterNamesForTooltip
} from '../utilities'

const BigText = styled.div`
  border: 0;
  height: auto;
  font-size: ${props => props.textSize}px;
  text-align: center;
  margin-bottom: ${props => props.marginBottom && '6rem'};
  margin-top: ${props => props.marginTop && '53px'};
`
const ReasonText = styled.p`
  font-size: ${props => props.textSize}px;
  font-weight: 100;
  max-width: ${props => props.maxWidth}px;
  text-align: left;
`

const FlexibleReasonText = styled.p`
  flex: 1 1 100%;
  font-size: ${props => props.textSize}px;
  font-weight: 100;
  text-align: left;
`

const Text = styled.p`
  word-break: break-word;
  font-size: ${props => props.textSize}px;
  margin-bottom: 0;
  text-align: ${props => props.align || 'center'};
`

const ReasonAvatarWrapper = styled.div`
  min-width: 200px;
`

const CopyInviteLinkWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

const CopySuccess = styled.p`
  display: block;
  color: green;
  margin-top: 15px;
`

const OptionText = ({ option }) => (
  <div className="col-6 w-100 mb-3">
    <Text textSize={25} align="center">{option.text}</Text>
  </div>
)

const OptionCount = ({ questionId, option, mutateVote }) => (
  <div className="col-6 d-inline-flex justify-content-center w-100">
    <Text textSize={45} data-tip={getVoterNamesForTooltip({ baseEntity: option })} data-html={true}>
      {option.count}
    </Text>
    {!option.hasVoted && (
      <div className="hvr-icon-grow">
        <i
          style={{ cursor: 'pointer' }}
          className="d-block ri-add-circle-line ms-2 mt-2 pt-1 ri-2x hvr-icon"
          onClick={async () => mutateVote({ questionId, optionId: option.id })}
        ></i>
      </div>
    )}
  </div>
)

const Avatar = ({ src }) => {
  const [ loaded, setLoaded ] = useState(false)
  return (
    <AvatarImg
      alt="headshot"
      className="ms-3"
      src={src}
      onLoad={() => {
        setLoaded(true)
      }}
      loaded={loaded}
      referrerpolicy="no-referrer"
    />
  )
}
const AvatarSection = ({ question }) => {
  const { authorPhotoUrl, authorName = '' } = question
  return (
    <div className="d-flex mt-5 text-center justify-content-center">
      <Avatar src={authorPhotoUrl} />
      {`${authorName.split(' ')[0]} wants to know...`}
    </div>
  )
}

const ReasonColumn = ({ questionId, option, mutateVote, mutateReason, mobile }) => {
  return (
    <div className={'d-flex flex-column align-self-center'}>
      {getOr([], 'reasons', option).map((reason, i) => {
        const hasVoted = reason.userHasVotedForThisReason
        return (
          <div className="d-flex mb-1 ms-4 align-self-left" key={reason.text} style={{ height: 'fit-content' }}>
            <ReasonAvatarWrapper className="d-flex">
              <Avatar src={reason.authorPhotoUrl} />
              <ReasonText className="" textSize={16} maxWidth={115}>
                {reason.authorName}
              </ReasonText>
            </ReasonAvatarWrapper>
            <div className="d-flex">
              <ReasonText textSize={16} data-tip={getVoterNamesForTooltip({ baseEntity: reason })} data-html={true}>
                {reason.count}
              </ReasonText>
              <div className="hvr-icon-grow">
                <i
                  style={{
                    cursor: 'pointer',
                    color: hasVoted ? 'black' : 'lightgray'
                  }}
                  className="d-block ri-arrow-up-line ms-2 me-3 hvr-icon"
                  onClick={() => mutateVote({ questionId, reasonId: reason.id, removeVote: hasVoted })}
                ></i>
              </div>
              <FlexibleReasonText textSize={16}>{reason.text}</FlexibleReasonText>
            </div>
          </div>
        )
      })}
      <div>
        <form
          className="d-flex"
          onSubmit={e => {
            e.preventDefault()
            mutateReason({
              reasons: [ { text: e.target && e.target[0].value, optionId: option.id, questionId } ],
              questionId
            })
            if (e.target) {
              e.target[0].value = ''
            }
          }}
        >
          <ReasonInput
            className="w-100 hvr-grow"
            placeholder="+ add a reason"
            textSize={16}
            light
            onKeyPress={preventNoValueSubmit}
          />
        </form>
      </div>
    </div>
  )
}

const QuestionDetails = () => {
  const [ showCopyMessage, setShowCopyMessage ] = useState(false)
  const { currentUser, logout } = useAuth()
  const { id } = useParams()
  const { data: question, isLoading, error } = useFetchQuestion({ id })
  const { mutate: mutateVote } = useOptimisticMutate(`QUESTION_${id}_KEY`, vote, data => old => {
    const isOptionVote = !!data.optionId
    return isOptionVote
      ? {
          ...old,
          options: swapOptionVote({ options: old.options, optionId: data.optionId, currentUser })
        }
      : {
          ...old,
          options: voteForReason({ reasonId: data.reasonId, options: old.options, currentUser })
        }
  })

  const { mutate: mutateReason } = useOptimisticMutate(`QUESTION_${id}_KEY`, createReasons, data => old => {
    return {
      ...old,
      options: addNewReason({ data, old, currentUser })
    }
  })

  const showSpinner = !question && isLoading

  if (error) return <div className="text-center justify-content-center mt-5">Error</div>
  if (showSpinner)
    return (
      <>
        <NavBar currentUser={currentUser} logout={logout} />
        <div className="text-center justify-content-center mt-5">
          <Loader
            type="TailSpin"
            color="#EEEEEE"
            height={150}
            width={150}
            timeout={10000} //10 secs
          />
        </div>
      </>
    )

  return (
    <>
      <NavBar currentUser={currentUser} logout={logout} />
      <div className="text-center justify-content-center">
        {/* Wants to know section */}
        <AvatarSection question={question} />
        <BigText marginTop className="w-100" textSize={35}>
          {question.text}
        </BigText>
        <div className="container mt-5">
          <div className="row row-cols-xs-1 row-cols-md-3">
            {question.options.map(option => (
              <div className="d-flex flex-column flex-grow-1 flex-shrink 1" key={option.text}>
                <div className="d-inline-flex flex-column-reverse w-100">
                  <OptionText option={option} />
                  <OptionCount questionId={id} option={option} mutateVote={mutateVote} />
                </div>
                <ReasonColumn questionId={id} option={option} mutateVote={mutateVote} mutateReason={mutateReason} />
              </div>
            ))}
          </div>
        </div>
        <ReactTooltip />
        <CopyInviteLinkWrapper>
          <CopyToClipboard
            text={`${webBaseUrl}/question/${id}`}
            onCopy={() => {
              setShowCopyMessage(true)
              // Remove the success copy after 3.5 seconds
              setTimeout(() => setShowCopyMessage(false), 3500)
            }}
          >
            <button className="btn btn-outline-dark">Copy Question Invite Link</button>
          </CopyToClipboard>
          {showCopyMessage && <CopySuccess>Link Copied!</CopySuccess>}
        </CopyInviteLinkWrapper>
      </div>
    </>
  )
}

export default QuestionDetails
