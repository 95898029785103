import { useState, useCallback } from 'react'
import qs from 'query-string'
import styled from 'styled-components'
import { useHistory, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { auth } from '../firebase'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useFetchQuestionsTotal } from '../api'
import { Header, SubHeader } from './common'

const TextWrapper = styled.div`
  cursor: pointer;
`

const Home = () => {
  const { push, location } = useHistory()
  const { currentUser } = useAuth()
  const [ error, setError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const { data } = useFetchQuestionsTotal()
  const { q } = qs.parse(location.search)

  const onLoginClick = useCallback(async e => {
    e.preventDefault()
    try {
      setError('')
      setLoading(true)
      await signInWithPopup(auth, new GoogleAuthProvider())
      // Await the token
      await auth.currentUser.getIdToken() && push(q ? `/question/${q}` : '/')
    } catch {
      setError('Failed to log in')
    }
    setLoading(false)
  }, [ push, q ])

  if(currentUser) return <Redirect to="/dashboard" />

  return (
    <div className="d-flex text-center flex-column vh-100 justify-content-around">
      <div className="d-flex flex-column">
        <Header>D-cide</Header>
        <SubHeader>{data && data.total ? `${data.total || 'Unknown'} decisions made to date` :  'Loading...'}</SubHeader>
      </div>
      <div className="mb-4 d-flex justify-content-between">
        <TextWrapper onClick={onLoginClick}>
          {
            (error && <SubHeader className="text-end">Error. Click to try again.</SubHeader>) ||
            (loading && <SubHeader className="text-end">Loading...</SubHeader>) ||
            <SubHeader className="text-end">Login</SubHeader>
          }


        </TextWrapper>
        <TextWrapper onClick={() => push('/create')}>
          <SubHeader className="text-end">+ create a question</SubHeader>
        </TextWrapper>
      </div>
    </div>
  )
}

export default Home
