import { clientFetchWithKey, getUrl } from './config'

// Each reason should have an object with the signature of
// { text, optionId, questionId }
export const createReasons = async ({ reasons = [], questionId }) => {
  return clientFetchWithKey(
    getUrl(`/questions/${questionId}/reasons`), {
      method: 'PATCH',
      body: JSON.stringify({ reasons, questionId })
    }
  )
}
