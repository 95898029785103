import { times, compact } from 'lodash'
import * as Yup from 'yup'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useHistory } from 'react-router-dom'
import { Formik, FieldArray } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { auth } from '../firebase'
import { MainQuestionInput, ReasonInput, OptionInput } from './common'
import { NavBar } from './NavBar'
import { textAreaAdjust, preventNoValueSubmit } from '../utilities'
import { createQuestion, createReasons } from '../api'
import { useAuth } from '../contexts/AuthContext'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

const createSchema = Yup.object().shape({
  text: Yup.string().max(999, 'Text is too long.').required('Required')
})

const CreateAQuestionForm = () => {
  const history = useHistory()
  const { currentUser, logout } = useAuth()
  const [ numOptions, setNumOptions ] = useState(2)
  const queryClient = useQueryClient()

  return (
    <>
      <NavBar currentUser={currentUser} logout={logout} />
      <Formik
        initialValues={{ text: '', options: []}}
        validationSchema={createSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // If no user, make sure we sign in first...
          if (!currentUser) {
            try {
              await signInWithPopup(auth, new GoogleAuthProvider())
            } catch {
              console.error('Failed to log in')
            }
          }

          // Start the process to create a question with options
          const question = await createQuestion(values)
          const { id: questionId, options = []} = question

          // Create a mapping of option
          // Reasons need questionId, optionId, text
          const optionDict = options.reduce((acc, current) => {
            acc[current.text] = current.id
            return acc
          }, {})

          const valueKeys = Object.keys(values)
          const reasons = valueKeys.reduce((acc, current) => {
            if (current.includes('reasons')) {
              const withId = values[current].map(reason => ({ text: reason.text, optionId: optionDict[reason.option], questionId }))
              return acc.concat(withId)
            }
            return acc
          }, [])

          // Now we need to add a the reasons to this after we have the option ids created
          const resQuestion = await createReasons({ reasons, questionId })

          queryClient.setQueryData(`QUESTION_${questionId}_KEY`, resQuestion)
          history.push(`/question/${questionId}`)
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="text-center justify-content-center container">
              <div>
                <MainQuestionInput
                  name="text"
                  className="mt-5 w-75"
                  placeholder="Type your question here..."
                  textSize={35}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyUp={e => textAreaAdjust(e.target)}
                  marginBottom
                />
              </div>
              <div>
                {/* Options Row */}
                <div className="row">
                  <FieldArray
                    name="options"
                    render={helpers => {
                      return times(numOptions, optionIndex => (
                        <div className="col" key={optionIndex}>
                          <OptionInput
                            name={`options.${optionIndex}`}
                            className="col w-100"
                            placeholder={`Option ${optionIndex + 1}`}
                            textSize={25}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyPress={preventNoValueSubmit}
                          />
                          <FieldArray
                            name={`reasons-option-${optionIndex}`}
                            render={arrayHelpers => (
                              <div className="col w-100">
                                {times(compact(values[`reasons-option-${optionIndex}`]).length + 1, i => (
                                  <div key={i}>
                                    <ReasonInput
                                      // className={`w-100 ${
                                      //   i === values[`reasons-option-${optionIndex}`].length ? 'hvr-grow' : ''
                                      // }`}
                                      name={`reasons-option-${optionIndex}.${i}`}
                                      placeholder="+ add a reason"
                                      textSize={16}
                                      onChange={(e) => {
                                        arrayHelpers.replace(i, { option: values.options[optionIndex], text: e.target.value })
                                      }}
                                      onBlur={handleBlur}
                                      onKeyPress={preventNoValueSubmit}
                                      light
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </div>
                      ))
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 text-center d-flex flex-column">
              <Button variant="outline-secondary" className="mb-4" disabled={numOptions === 3} onClick={() => setNumOptions(prev => prev + 1)}>
                Add Additional Option
              </Button>
              <Button variant="outline-secondary" type="submit">
                Publish
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateAQuestionForm
