import React from 'react'
import nth from 'lodash/nth'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const id = nth(pathname.split('/'), 2)
  const redirectBackToQuestion = !currentUser && id

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectBackToQuestion ? `/login?q=${id}` : '/login'} />
        )
      }}
    ></Route>
  )
}
