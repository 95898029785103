import { getUrl, authorizedFetch } from './config'

export const validateUser = async () =>  {
  const key = getUrl('/users/validate')
  await authorizedFetch(
    key,
    {
    method: 'POST'
  })
}
