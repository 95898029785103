import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { NavBar } from './NavBar'

const HowItWorks = () => {
  const { push } = useHistory()
  const { currentUser, logout } = useAuth()
  return (
    <div className="text-left justify-content-center container">
      <NavBar currentUser={currentUser} logout={logout} />
      <h2 className="mt-5 mb-3">What is it</h2>
      <p className="mb-3">D-cide is a way to get feedback on decisions from your family, friends, or co-workers.</p>
      <h5 className="mb-3">Use Cases</h5>
      <ul class="list-group">
        <li class="list-group-item">Poll your friends to see if you should eat at restaurant A or restaurant B</li>
        <li class="list-group-item">
          Poll your family to see if your next family reunion should take place in Mexico or Alaska.
        </li>
        <li class="list-group-item">
          Poll your coworkers to see if you should pursue business strategy A or strategy B in the upcoming quarter.
        </li>
      </ul>
      <p className="mt-3 mb-3">
        We make it easy and speedy to gather all of the input from your trusted advisors. It should be much easier than
        text threads, emails or phone calls.
      </p>
      <h2 className="mt-5 mb-3">How it works</h2>
      <p className="mb-3">
        If you are landing on a page for the first time, vote once with your overall recommendation, add supporting
        reasons, and upvote your favorite arguments. Note that you can add reasons and upvotes to either side regardless
        of your main vote. After all, sometimes folks with different views make good points, right?
      </p>
      <button onClick={()=> push('/create')} className="btn btn-outline-dark mt-5">Create a Question</button>
    </div>
  )
}

export default HowItWorks
