import { useQueryClient , useMutation } from 'react-query'
import { auth } from '../firebase'

export const getUrl = path => `${process.env.REACT_APP_API_URL}${path}`

export const parseJson = res => res.json()

export const authorizedFetch = async (url, options) => {
  const bearerToken = await auth.currentUser.getIdToken()
  const res = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`
    },
    ...options
  })
  return res
}

export const clientFetchWithKey = async (url, options) => {
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    throw new Error(response.statusText || 'Network response was not ok')
  }
  const json = await response.json()
  return json
}

export const useOptimisticMutate = (key, updateFn, parser) => {
  const queryClient = useQueryClient()

  return useMutation(updateFn, {
    // When mutate is called:
    onMutate: async data => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(key)

      // Snapshot the previous value
      const prev = queryClient.getQueryData(key)
      // Optimistically update to the new value
      // parser is a function with footprint (new) => (old) => do work here
      queryClient.setQueryData(key, parser(data))

      // Return a context object with the snapshotted value
      return { prev }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(key, context.prev)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(key)
    }
  })

}
