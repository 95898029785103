import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

export const AvatarImg = styled.img`
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 1rem;

  display: ${props => (props.loaded ? 'block' : 'none')};
`

export const MainQuestionInput = styled.textarea`
  border: 0;
  font-size: ${props => props.textSize}px;
  line-height: 1.2;
  height: 60px;
  padding: 0;
  text-align: center;
  margin-bottom: ${props => props.marginBottom && '6rem'};
  font-weight: ${props => props.light && 100};
  resize: none;
  overflow: hidden;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: 100;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 100;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: 100;
  }
  :focus {
    outline: none;
  }

  :focus::placeholder {
    color: transparent;
  }
`

export const ReasonInput = styled.input`
border: 0;
font-size: ${props => props.textSize}px;
line-height: 1.2;
height: 60px;
padding: 0;
text-align: center;
margin-bottom: ${props => props.marginBottom && '6rem'};
font-weight: ${props => props.light && 100};
resize: none;
overflow: hidden;

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 100;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 100;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 100;
}
:focus {
  outline: none;
}

:focus::placeholder {
  color: transparent;
}
`

export const OptionInput = styled(TextareaAutosize)`
border: 0;
font-size: ${props => props.textSize}px;
line-height: 1.2;
height: 60px;
padding: 0;
text-align: center;
margin-bottom: ${props => props.marginBottom && '6rem'};
font-weight: ${props => props.light && 100};
resize: none;
overflow: hidden;

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 100;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 100;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 100;
}
:focus {
  outline: none;
}

:focus::placeholder {
  color: transparent;
}
`

export const Header = styled.p`
  font-size: 144px;
  font-weight: bold;
`

export const SubHeader = styled.p`
  font-size: 24px;
  font-weight: 200;
`

