import React, { useContext, useState, useEffect } from 'react'
import { validateUser } from '../api'
import { auth } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [ currentUser, setCurrentUser ] = useState()
  const [ loading, setLoading ] = useState(true)
  const [ authToken, setAuthToken ] = useState()

  // Set up listener for new user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
        setLoading(false)
        user.getIdToken().then(token => setAuthToken(token))
        // Call the API to make sure we have the userid in the database
        validateUser()
      } else {
        setCurrentUser(undefined)
        setLoading(false)
        setAuthToken('')
      }
    })

    return unsubscribe
  }, [])

  const logout = () => auth.signOut()

  const value = {
    authToken,
    currentUser,
    logout
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
