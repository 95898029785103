import { useQuery } from 'react-query'
import { clientFetchWithKey, getUrl } from './config'
import { QUESTIONS_KEY , QUESTIONS_TOTAL_KEY } from '../constants'

export const useFetchQuestionsTotal = () => {
  const { error, data, isLoading } = useQuery(
    QUESTIONS_TOTAL_KEY,
    () => fetch(getUrl('/questions/all')).then(res => res.json())
  )
  return { error, data, isLoading }
}

export const useFetchQuestions = () => {
  const { error, data, isLoading } = useQuery(
    QUESTIONS_KEY,
    () => clientFetchWithKey(getUrl('/questions'))
  )
  return { error, data, isLoading }
}

export const useFetchQuestion = ({ id }) => {
  return useQuery(
    `QUESTION_${id}_KEY`,
    () => clientFetchWithKey(getUrl(`/questions/${id}`)),
    { enabled: !!id, retry: 4 }
  )
}

export const useFetchQuestionPreview = ({ id }) => {
  const { error, data, isLoading, mutate, isError } = useQuery(
    `QUESTION_PREVIEW_${id}_KEY`,
    () => fetch(getUrl(`/questions/preview/${id}`)).then(res => res.json()),
    { enabled: !!id, retry: 4, refetchInterval: false }
  )
  return { error, data, isLoading, mutate, isError }
}

export const createQuestion = async ({
  options,
  text
}) => {
  return clientFetchWithKey(getUrl('/questions'), {
    method: 'POST',
    body: JSON.stringify({ options, text })
  })
}
