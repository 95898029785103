import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useFetchQuestionPreview } from '../api'
import { auth } from '../firebase'
import { Header, SubHeader } from './common'

export default function Login() {
  const { push, location } = useHistory()
  const { q } = qs.parse(location.search)
  const { data } = useFetchQuestionPreview({ id: q })
  const handleLogin = useCallback(async (e) => {
    e.preventDefault()
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      const { user } = result
      user && push(q ? `/question/${q}` : '/' )
    } catch {
      console.error('Failed to log in')
    }
  }, [ push, q ])

  return (
    <div className="d-flex text-center flex-column vh-100 mt-5 pt-5">
      <div className="d-flex flex-column">
        <Header>D-cide</Header>
        <SubHeader>{data && data.authorFirstName ? `${data.authorFirstName} wants your opinion on something, please login to help...` :  ''}</SubHeader>
      </div>
      <div className="d-flex align-self-center">
        <button onClick={handleLogin} className="btn btn-outline-dark">Login</button>
      </div>
    </div>
  )
}
