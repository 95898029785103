import React, { useState } from 'react'
import styled from 'styled-components'
import { Card, Row, Col } from 'react-bootstrap'
import Loader from 'react-loader-spinner'
import moment from 'moment'
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { NavBar } from './NavBar'
import { useFetchQuestions } from '../api'
import { SubHeader } from './common'

const CREATED = 'CREATED'
const CONTRIBUTED = 'CONTRIBUTED'

const TabbedRoutesContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TabbedText = styled.p`
  border-bottom: ${props => (props.active ? '3px solid black' : '')};
  color: ${props => (props.active ? 'black' : 'lightgray')};
  cursor: pointer;
  font-size: 20px;
  margin: 0 15px;
`

export default function Dashboard() {
  const [ selectedTab, setSelectedTab ] = useState(CREATED)
  const { data, isLoading } = useFetchQuestions()
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  return (
    <>
      <NavBar currentUser={currentUser} logout={logout} />
      <SubHeader style={{ cursor: 'pointer' }} className="mt-4 text-center" onClick={() => history.push('/create')}>
        + create a question
      </SubHeader>
      <h2 className="text-center mb-4 mt-4">Your Questions</h2>
      <TabbedRoutesContainer className="mb-4">
        <TabbedText active={selectedTab === CREATED} onClick={() => setSelectedTab(CREATED)}>
          Created
        </TabbedText>
        <TabbedText active={selectedTab === CONTRIBUTED} onClick={() => setSelectedTab(CONTRIBUTED)}>
          Contributed
        </TabbedText>
      </TabbedRoutesContainer>
      <Row xs={1} md={2} className="g-4">
        {(isLoading && (
          <div className="text-center justify-content-center mt-5 w-100">
            <Loader
              type="TailSpin"
              color="#EEEEEE"
              height={150}
              width={150}
              timeout={10000} //10 secs
            />
          </div>
        )) ||
          (data &&
            data[selectedTab === CREATED ? 'created' : 'contributed'].map((question, i) => (
              <Col key={i}>
                <Card
                  role="button"
                  className="m-2"
                  style={{ minHeight: 150 }}
                  onClick={() => history.push(`/question/${question.id}`)}
                >
                  <Card.Body>
                    <Card.Text>{question.text}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex justify-content-between">
                      <span>{`Created: ${moment(question.createdAt).format('MMMM Do YYYY')}`}</span>
                      <span>{`Votes: ${question.totalQuestionVotes}`}</span>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            )))}
      </Row>
    </>
  )
}
