import { getOr, map } from 'lodash/fp'
import dayjs from 'dayjs'

export const isProduction = process.env.REACT_APP_API_URL === 'https://api.decideit.xyz'
export const webBaseUrl = isProduction ? 'https://decideit.xyz' : 'http://localhost:3000'

export const swapOptionVote = ({ options, optionId, currentUser = {}}) =>
  options.map(option => {
    // If OptionId is the targeted option
    if (option.id === optionId) {
      // Add + to vote
      option.count += 1
      // Add voter name to voters array
      option.voters = [ ...option.voters, { name: currentUser.displayName } ]
      // Change hasVoted to true
      option.hasVoted = true
    }
    // Else its another option
    else {
      // If user previously voter for this other option
      const filteredOptions = option.voters.filter(voter => voter.name !== currentUser.displayName)
      const userHadVotedForOldOption = option.voters.length !== filteredOptions.length
      if (userHadVotedForOldOption) {
        // Assign filtered list to voters list
        option.voters = filteredOptions
        // Reduce the count
        option.count -= 1
        // Change the option hasVoted
        option.hasVoted = false
      }
    }
    return option
  })

export const voteForReason = ({ reasonId, options, currentUser = {}}) => {
  return map(
    option => ({
      ...option,
      reasons: map(reason => {
        if (reason.id === reasonId) {
          const shouldRemove = reason.userHasVotedForThisReason
          return {
            ...reason,
            count: shouldRemove ? reason.count - 1 : reason.count + 1,
            userHasVotedForThisReason: shouldRemove ? false : true,
            voters: shouldRemove
              ? reason.voters.filter(voter => !voter.id === currentUser.uid)
              : [ ...reason.voters, { name: currentUser.displayName } ]
          }
        }
        return reason
        }, option.reasons).sort((a, b) => b.count - a.count || (dayjs(a.createdAt).isBefore(b.createdAt) ? -1 : 1))
    }),
    options
  )
}

export const addNewReason = ({ data, old, currentUser }) => {
  const reason = data.reasons[0]
  const optionId = reason.optionId

  return old.options.map(option => {
    // Option should remain unchanged
    if (optionId !== option.id) return option
    // should return new option object with voted reason
    return {
      ...option,
      reasons: [
        ...option.reasons,
        {
          text: reason.text,
          count: 1,
          authorPhotoUrl: currentUser.photoURL,
          authorName: currentUser.displayName,
          userHasVotedForThisReason: true,
          voters: [
            {
              id: currentUser.uid,
              name: currentUser.displayName,
              photo: currentUser.photoURL
            }
          ]
        }
      ]
    }
  })
}

export const textAreaAdjust = element => {
  element.style.height = '1px'
  element.style.height = 25 + element.scrollHeight + 'px'
}

export const preventNoValueSubmit = e => {
  const key = e.charCode || e.keyCode || 0
  // If we hit enter but dont have a value
  if (key === 13 && !e.target.value) {
    e.preventDefault()
    return false
  }
}

export const getVoterNamesForTooltip = ({ baseEntity }) =>
  getOr([], 'voters', baseEntity)
    .map(voter => voter.name)
    .sort()
    .join(' <br /> ')
