import React from 'react'
import { Container } from 'react-bootstrap'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { AuthProvider } from '../contexts/AuthContext'
import Home from './Home'
import CreateAQuestion from './CreateAQuestion'
import Dashboard from './Dashboard'
import QuestionDetails from './QuestionDetails'
import HowItWorks from './HowItWorks'
import PrivateRoute from './PrivateRoute'
import Login from './Login'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 6000,
      refetchOnWindowFocus: false,
      // Default behavior is exponential backoff
      // See https://react-query.tanstack.com/guides/query-retries#retry-delay
      retry: true,
      // Let React Query determine which return values we are using for re-renders
      notifyOnChangeProps: 'tracked'
    }
  }
})

const GlobalStyle = createGlobalStyle`
  /* Grow */
  .hvr-grow {
      display: inline-block;
      vertical-align: middle;
      transform: translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      transition-duration: 0.3s;
      transition-property: transform;
  }

  .hvr-grow:hover,
  .hvr-grow:focus,
  .hvr-grow:active {
      transform: scale(1.1);
  }

  /* Icon Grow */
  .hvr-icon-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-icon-grow .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-icon-grow:hover .hvr-icon, .hvr-icon-grow:focus .hvr-icon, .hvr-icon-grow:active .hvr-icon {
    -webkit-transform: scale(1.3) translateZ(0);
    transform: scale(1.3) translateZ(0);
  }
`

function App() {
  return (
    <Container className="flex align-items-center justify-content-center">
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/create" component={CreateAQuestion} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/how-it-works" component={HowItWorks} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/question/:id" component={QuestionDetails} />
            </Switch>
          </AuthProvider>
        </QueryClientProvider>
      </Router>
      <GlobalStyle />
    </Container>
  )
}

export default App
